import { initEmailAutocompleteDropdown } from '../../../../../Common/Resources/templates/components/EmailAutocompleteDropdown/initEmailAutocompleteDropdown';
import { initShowMoreContent } from '../components/initShowMoreContent';
import { initEmailForm } from '../components/forms/initEmailForm';
import { initTestimonialsSlider } from '../components/sliders/initTestimonialsSlider';

document.addEventListener('DOMContentLoaded', () => {
    initEmailAutocompleteDropdown();
    initShowMoreContent();
    initEmailForm();
    initTestimonialsSlider();
});
