import Swiper from 'swiper/bundle';

export const initTestimonialsSlider = () => {
    // VARS
    const sliderElem = document.querySelector('[data-testimonials-slider]');
    if (!sliderElem) return;

    // INITS
    new Swiper('[data-testimonials-slider]', {
        loop: true,
        slidesPerView: 1.1,
        spaceBetween: 20,
        grabCursor: true,
        pagination: {
            el: '[data-testimonials-slider-pagination]',
            clickable: true,
        },
        breakpoints: {
            680: {
                slidesPerView: 1.2,
                spaceBetween: 10,
            },
            1024: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            1366: {
                slidesPerView: 3,
                spaceBetween: 14,
            },
        },
    });
};
