import { isValidMail } from '../../../../../../Common/Resources/src_front/js/helper-functions/isValidMail';

export const initEmailForm = () => {
    // VARS
    const forms = document.querySelectorAll('[data-email-form]');
    if (!forms.length) return;

    // INITS
    [...forms].forEach((form) => {
        initForm(form);
    });
};

function initForm(form) {
    // VARS
    const fieldsSelector = {
        userEmail: '[data-email-form-email]',
        agreeCheckbox: '[data-email-form-checkbox]',
    };
    const userEmail = form.querySelector(`${fieldsSelector.userEmail}`);
    const agreeCheckbox = form.querySelector(`${fieldsSelector.agreeCheckbox}`);
    const classShowErrorMessage = 'is-error';
    let isValidForm = true;

    // INITS
    if (form.dataset.emailForm === 'email-page') {
        userEmail.focus();
    }

    // LISTENERS
    form.addEventListener('submit', handleOnSubmit, false);

    userEmail.addEventListener('focus', handleOnFocusFieldUserEmail, false);

    agreeCheckbox?.addEventListener('change', handleOnChangeAgreeCheckbox, false);

    // HANDLERS
    function handleOnSubmit(event) {
        event.preventDefault();
        validateUserEmail();

        if (agreeCheckbox) {
            validateAgreeCheckbox();
        }

        if (!isValidForm) {
            isValidForm = true;
        } else {
            if (window.pageLoader) {
                window.pageLoader.show();
            }

            this.submit();
        }
    }

    function handleOnFocusFieldUserEmail() {
        hideErrorMessage(fieldsSelector.userEmail);
    }

    function handleOnChangeAgreeCheckbox() {
        hideErrorMessage(fieldsSelector.agreeCheckbox);
    }

    // METHODS & FUNCTIONS
    function validateUserEmail() {
        hideErrorMessage(fieldsSelector.userEmail);

        if (!userEmail.value.trim()) {
            showErrorMessage(fieldsSelector.userEmail, 'required');
            isValidForm = false;
        } else if (!isValidMail(userEmail.value.trim())) {
            showErrorMessage(fieldsSelector.userEmail, 'invalid');
            isValidForm = false;
        } else {
            hideErrorMessage(fieldsSelector.userEmail);
        }
    }

    function validateAgreeCheckbox() {
        hideErrorMessage(fieldsSelector.agreeCheckbox);

        if (agreeCheckbox.checked) {
            hideErrorMessage(fieldsSelector.agreeCheckbox);
        } else {
            showErrorMessage(fieldsSelector.agreeCheckbox, 'checkbox');
            isValidForm = false;
        }
    }

    function hideErrorMessage(selector) {
        const field = form.querySelector(selector);
        const errors = field.closest('[data-input-wrapper]').querySelectorAll('[data-field-error]');

        [...errors].forEach((error) => {
            error.classList.remove(classShowErrorMessage);
        });
    }

    function showErrorMessage(selector, type) {
        const field = form.querySelector(selector);
        const error = field.closest('[data-input-wrapper]').querySelector(`[data-field-error="${type}"]`);

        error.classList.add(classShowErrorMessage);
    }
}
